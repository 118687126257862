import * as React from 'react';
import { useForm, OnSubmit } from 'react-hook-form';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

interface FormValues {
	fullName?: string;
	company?: string;
	phone?: string;
	email?: string;
	message?: string;
}

const ContactFormPage: React.FC = () => {
	const { register, errors, handleSubmit } = useForm<FormValues>();

	const handleOnSubmit: OnSubmit<FormValues> = (data, event) => {
		event?.target.submit();
	};

	return (
		<Layout>
			<SEO title='Contact' />
			<div id='contact-form' className='bg-white pt-24'>
				<section className='container max-w-5xl mx-auto mx-4'>
					<h1 className='w-full my-2 text-4xl uppercase font-bold leading-tight text-center text-gray font-header'>
						Get in touch
        </h1>
					<div className='flex flex-wrap'>
						<div className='w-full p-4'>
							<p className='text-gray-light text-center text-xl'>We are happy to answer your questions about our services. We will be in touch as soon as possible.</p>
						</div>
					</div>
					<div className='p-4 flex justify-center'>
						<form name='contact' className='w-full max-w-lg' onSubmit={handleSubmit(handleOnSubmit)} method='post' action='/contact-success' data-netlify='true' data-netlify-honeypot='bot-field'>
							<input type='hidden' name='bot-field' />
							<input type='hidden' name='form-name' value='contact' />
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<label className='block tracking-wide text-gray-light font-bold mb-1' htmlFor='fullName'>
										Full Name
                	</label>
									<input
										id='fullName'
										name='fullName'
										type='text'
										className='bg-gray-control block appearance-none border-2 border-gray-control rounded w-full py-2 px-4 mb-1 text-gray leading-tight focus:outline-none focus:bg-white focus:border-blue-light'
										defaultValue=''
										ref={register({
											required: 'Please enter your full name',
											maxLength: {
												value: 50,
												message: 'Please enter up to 50 characters for the name'
											}
										})}
									/>
									{errors.fullName && <p className="text-red text-xs italic">{errors.fullName.message}</p>}
								</div>
							</div>
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<label className='block tracking-wide text-gray-light font-bold mb-1' htmlFor='company'>
										Company
                	</label>
									<input
										id='company'
										name='company'
										type='text'
										className='bg-gray-control block appearance-none border-2 border-gray-control rounded w-full py-2 px-4 mb-1 text-gray leading-tight focus:outline-none focus:bg-white focus:border-blue-light'
										defaultValue=''
										ref={register}
									/>
								</div>
							</div>
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<label className='block tracking-wide text-gray-light font-bold mb-1' htmlFor='phone'>
										Phone
                	</label>
									<input
										id='phone'
										name='phone'
										type='text'
										className='bg-gray-control block appearance-none border-2 border-gray-control rounded w-full py-2 px-4 mb-1 text-gray leading-tight focus:outline-none focus:bg-white focus:border-blue-light'
										defaultValue=''
										ref={register}
									/>
								</div>
							</div>
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<label className='block tracking-wide text-gray-light font-bold mb-1' htmlFor='email'>
										Email
                	</label>
									<input
										id='email'
										name='email'
										type='text'
										className='bg-gray-control block appearance-none border-2 border-gray-control rounded w-full py-2 px-4 mb-1 text-gray leading-tight focus:outline-none focus:bg-white focus:border-blue-light'
										defaultValue=''
										ref={register({
											required: 'Please enter your email address',
											pattern: {
												value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
												message: 'Please enter a valid email address'
											}
										})}
									/>
									{errors.email && <p className="text-red text-xs italic">{errors.email.message}</p>}
								</div>
							</div>
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<label className='block tracking-wide text-gray-light font-bold mb-1' htmlFor='message'>
										Message
                	</label>
									<textarea
										id='message'
										name='message'
										rows={2}
										className='bg-gray-control block appearance-none border-2 border-gray-control rounded w-full py-2 px-4 mb-1 text-gray leading-tight focus:outline-none focus:bg-white focus:border-blue-light'
										defaultValue=''
										ref={register({
											required: 'Please enter a message',
											maxLength: {
												value: 255,
												message: 'Please enter up to 255 characters for the message'
											}
										})}
									/>
									{errors.message && <p className="text-red text-xs italic">{errors.message.message}</p>}
								</div>
							</div>
							<div className='flex flex-wrap -mx-3 mb-3'>
								<div className='w-full px-3'>
									<button className='shadow bg-blue-light hover:bg-blue focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded' type='submit'>
										Submit
                </button>
								</div>
							</div>
						</form>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default ContactFormPage;
